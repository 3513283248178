<template>
    <div class="c-logo lg:mr-12 xl:mr-32">
        <NuxtLink :to="localePath('/')">
            <div class="c-logo--image"></div>
        </NuxtLink>
    </div>
</template>

<script setup>
    let interval

    const randomNumber = (_max) => {
        let num = Math.round(Math.random() * _max + 1)

        return num;
    }

    const glitchIterator = (_speed) => {
        interval = setInterval(() => {
            const logo = document.querySelector(".c-logo--image")
            let digit = randomNumber(15)

            if (digit < 3) {
                logo.classList.add("c-anim--logo");
            } else {
                logo.classList.contains("c-anim--logo")
                ? logo.classList.remove("c-anim--logo")
                : null
            }
        }, _speed)
    }

    onMounted(() => {
        glitchIterator(300)
    })

    onBeforeUnmount(() => {
        clearInterval(interval)
    })
</script>
