<template>
    <nav
        class="c-main--menu-mobile c-main--menu-animated p-6 sm:p-8 md:p-12 lg:p-14 shadow-md"
    >
        <ul class="c-main--menu-list">
            <li>
                <NuxtLink :to="localePath('about-us')" v-on:click.native="closeMobileMenu">About us</NuxtLink>
            </li>
            <li>
                <NuxtLink :to="localePath('endemics')" v-on:click.native="closeMobileMenu">For Endemics</NuxtLink>
            </li>
            <li>
                <NuxtLink :to="localePath('non-endemics')" v-on:click.native="closeMobileMenu">For Non-Endemics</NuxtLink>
            </li>
            <li>
                <NuxtLink :to="localePath('contact')" v-on:click.native="closeMobileMenu">Contact</NuxtLink>
            </li>
        </ul>

        <menu class="c-lang--menu-mobile">
            <LangMenu />
        </menu>
    </nav>
</template>

<script setup>
    const closeMobileMenu = () => {
        const mobileMenu = document.querySelector(".c-main--menu-mobile");
        const icon = document.querySelector(".c-burger--icon");

        if (mobileMenu.classList.contains("is-opened")) {
            mobileMenu.classList.remove("is-opened");
            icon.classList.remove("open");
        } else {
            mobileMenu.classList.add("is-opened");
            icon.classList.add("open");
        }
    }
</script>

<style lang="scss">
    .c-logo-menu--container-mobile {
        margin-bottom: 2.5rem;
    }

    .c-main--menu-mobile {
        position: fixed;
        top: -20px;
        width: 100%;
        z-index: 10;
        left: 50%;
        transform: translate(-50%, -100%);
        background-color: #000000;
        text-align: center;
        transition: all 0.3s ease-in-out;
        opacity: 0;

        .c-main--menu-list > li {
            padding: 0.5rem 1rem;
        }
    }
    
    .c-main--menu-mobile.is-opened {
        z-index: 20;
        transform: translate(-50%, 0%);
        height: auto;
        top: 90px;
        opacity: 1;
        transition: all 0.3s ease-in-out;
    }

    .c-lang--menu-mobile {
        display: flex;
        justify-content: center;
        padding: 0;

        .c-lang--menu {
            margin-left: 0.6rem !important;
            margin-right: 0.6rem;
        }
    }
</style>
