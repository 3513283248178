<template>
    <div class="c-main--menu-lang c-main--menu-animated flex justify-end">
        <NuxtLink
            v-for="locale in availableLocales"
            :key="locale.code"
            :to="switchLocalePath(locale.code)"
            class="c-lang--menu"
        >
            {{ locale.name }}
        </NuxtLink>
    </div>
</template>

<script setup>
    const { locales } = useI18n()

    const availableLocales = computed(() => {
        return locales.value
    })
</script>

<style lang="scss" scoped>
    .c-main--menu-lang {
        a {
            margin-left: 1.25rem;
        }
    }
</style>
