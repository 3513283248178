<template>
    <header
        class="flex items-center c-header--container p-6 sm:p-8 md:p-12 lg:p-14 shadow-md"
    >
        <div class="flex-1">
            <MainLogo />
        </div>

        <div class="grow hidden lg:block">
            <MainMenu />
        </div>

        <div class="flex-1 hidden lg:block">
            <LangMenu />
        </div>

        <div class="lg:hidden">
            <MobileMenu />
        </div>

        <div class="flex-1 block lg:hidden">
            <div class="flex justify-end ml-6">
                <div class="c-main--menu-toggle">
                    <div class="c-burger--icon" @click="openMobileMenu">
                        <span></span>
                        <span></span>
                        <span></span>
                    </div>
                </div>
            </div>
        </div>
    </header>
</template>

<script setup>
    const openMobileMenu = () => {
        const mobileMenu = document.querySelector(".c-main--menu-mobile");
        const icon = document.querySelector(".c-burger--icon");

        if (mobileMenu.classList.contains("is-opened")) {
            mobileMenu.classList.remove("is-opened");
            icon.classList.remove("open");
        } else {
            mobileMenu.classList.add("is-opened");
            icon.classList.add("open");
        }
    }
</script>

<style lang="scss">
    .c-logo {
        &--image {
            height: 100%;
            max-height: 35px;
            width: 100%;
            // object-fit: contain;
            position: relative;
            z-index: 100;
        }
    }
</style>
