<template>
    <nav class="c-main--menu c-main--menu-animated">
        <div class="c-main--menu-list" :class="props.objclass">
            <NuxtLink :to="localePath('about-us')">About us</NuxtLink>
            <NuxtLink :to="localePath('endemics')">For Endemics</NuxtLink>
            <NuxtLink :to="localePath('non-endemics')">For Non-Endemics</NuxtLink>
            <NuxtLink :to="localePath('contact')">Contact</NuxtLink>
        </div>
    </nav>
</template>

<script setup>
    const props = defineProps({
        objclass: String
    })
</script>

<style lang="scss" scoped>
    .c-main--menu {
        a {
            margin: 0 20px;
        }
    }
</style>
