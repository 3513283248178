const navbarController = {

  observeHeaderToMutate () {
    const header = document.querySelector('.c-header--container')
    // const style = header.currentStyle || window.getComputedStyle(header)

    window.addEventListener('scroll', () => {
      let tHeight = Math.abs(document.body.getBoundingClientRect().top);
      if (tHeight > 5) {
        header.classList.add('c-header--container-small')
      } else if (tHeight <= 15) {
        header.classList.remove('c-header--container-small')
      }
    })
  }

}

export default navbarController
